import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mb-4",attrs:{"justify":"space-between"}},[_c(VCol,{staticClass:"text-left"},[_c('span',{staticClass:"display-3 font-weight-light",domProps:{"textContent":_vm._s(_vm.bpm)}}),_c('span',{staticClass:"subheading font-weight-light me-1"},[_vm._v("BPM")]),_c(VFadeTransition,[(_vm.isPlaying)?_c(VAvatar,{attrs:{"color":_vm.color,"size":"12"}}):_vm._e()],1)],1),_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":_vm.color,"dark":"","depressed":"","fab":""},on:{"click":_vm.toggle}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.isPlaying ? _vm.icons.mdiPause : _vm.icons.mdiPlay)+" ")])],1)],1)],1),_c(VSlider,{attrs:{"color":_vm.color,"track-color":"grey","always-dirty":"","min":"40","max":"218","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,{attrs:{"color":_vm.color},on:{"click":_vm.decrement}},[_vm._v(" "+_vm._s(_vm.icons.mdiMinus)+" ")])]},proxy:true},{key:"append",fn:function(){return [_c(VIcon,{attrs:{"color":_vm.color},on:{"click":_vm.increment}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])]},proxy:true}]),model:{value:(_vm.bpm),callback:function ($$v) {_vm.bpm=$$v},expression:"bpm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }